import React from 'react';
// import Commento from 'react-commento';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Commento } from '../../components';

import css from './ListingPage.module.css';

const SectionComments = props => {
  const { reviews, fetchReviewsError } = props;

  return (
    <div className={css.sectionComments}>
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.commentsTitle" values={{ count: 0 }} />
      </Heading>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Commento id="test" />
    </div>
  );
};

export default SectionComments;
